/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue: #316ce8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #fb4f16 !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #36b9cc !default;
$cyan: #17a2b8 !default;
$gray-600: #6c757d !default;

/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
$theme-primary: #0b1239 !default;
$theme-primary-alt: #0a2d50 !default;
$theme-primary-alt2: #045475 !default;
$theme-secondary: #46955e !default;
$theme-secondary-alt: #d8f793 !default;
$theme-danger: $danger !default;
$headline-primary: $theme-primary !default;
$headline-secondary: $theme-secondary !default;
$theme-nav-primary: $theme-secondary !default;
$theme-nav-secondary: $theme-secondary !default;
$link-color: #284793 !default;

/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
$cta-red: #f43325 !default;
$cta-orange: #ff571f !default;
$cta-green: $theme-secondary !default;
$cta-blue: $theme-primary !default;
$cta-yellow: #ffc107 !default;
$cta-dark: #555555 !default;

// text color overrides
.text-theme-primary {
  color: $theme-primary !important;
}
.text-theme-primary-alt {
  color: $theme-primary-alt !important;
}
.text-theme-primary-alt2 {
  color: $theme-primary-alt2 !important;
}
.text-theme-secondary {
  color: $theme-secondary !important;
}
.text-theme-secondary-alt {
  color: $theme-secondary-alt !important;
}
.text-theme-danger {
  color: $theme-danger !important;
}
.border-theme-secondary {
  border-color: $theme-secondary !important;
}

/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: $theme-primary !important;
}
.bg-theme-primary-alt {
  background-color: $theme-primary-alt !important;
}
.bg-theme-primary-alt2 {
  background-color: $theme-primary-alt2 !important;
}
.bg-theme-secondary {
  background-color: $theme-secondary !important;
}
.bg-theme-secondary-alt {
  background-color: $theme-secondary-alt !important;
}
